









import Vue from 'vue'
import Header from '@/views/Header/Header.vue'

export default Vue.extend({
  components: {
    'layout-header': Header
  },
  computed: {
    withHeader () {
      return this.$route.meta.withHeader
    }
  },
  mounted () {
    setInterval(() => {
      this.$matomo && this.$matomo.ping()
    }, 15000)
  }
})
