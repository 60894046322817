import { StateInformation } from './definitions'
import apiService from '@/services/api'
import { DateTime } from 'luxon'
import { stringifyDate } from '@/helpers/dates'
import { createStore } from 'vue-reactive-store'

import { vrsStoreApp } from '@/store/app/store'

const state: StateInformation = {
  loading: false,
  error: null,
  data: null,
  all: null
}

const store = {
  name: 'information',
  state,
  computed: {
    dataToDisplay () {
      if (!store.state.all) return
      const infosDataBeforeCurrentTime = store.state.all.filter(i => i.datestring <= vrsStoreApp.state.data.referenceDate /* currentTime */)
      if (!infosDataBeforeCurrentTime) return null
      return infosDataBeforeCurrentTime[infosDataBeforeCurrentTime.length - 1]
    }
  },
  actions: {
    /**
     * Fetch latest data from weather stations
     *
     * @param {array} stations
     */
    async fetchInfosData (eventId, currentTime: string, realtime: boolean, timezone: string) {
      store.state.loading = true
      try {
        const responseInfos = await apiService.getInfos(eventId)
        store.state.all = responseInfos.data.map(d => {
          const currentUpdateTime = DateTime.fromISO(d.value_date, { setZone: true, zone: timezone })
          return {
            ...d.data.Info,
            valueDate: d.value_date,
            updateTime: currentUpdateTime.toFormat('T \'on\' EEE dd MMM'),
            datestring: stringifyDate(currentUpdateTime)
          }
        })
        if (realtime) {
          store.state.data = store.state.all // [store.state.all.length - 1]
        } else {
          // find the closer information from the currentTime
          store.actions.computeInfosDataToDisplay(currentTime)
        }
      } catch (e) {
        console.error(e)
        store.state.error = e
      }

      store.state.loading = false
    },

    computeInfosDataToDisplay (currentTime: string) {
      if (!store.state.all) return
      const infosDataBeforeCurrentTime = store.state.all.filter(i => i.datestring <= currentTime)
      if (infosDataBeforeCurrentTime) {
        store.state.data = infosDataBeforeCurrentTime // [infosDataBeforeCurrentTime.length - 1]
      } else {
        store.state.data = null
      }
    }
  }
}

export const fetchInfosData = store.actions.fetchInfosData
export const computeInfosDataToDisplay = store.actions.computeInfosDataToDisplay

export const vrsStoreInformation = createStore(store)
