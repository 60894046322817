<template>
  <div :id='id'/>
</template>

<script>
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

const COLOR_GOLD = '#b9a26c'

export default {
  name: 'Track',
  props: {
    location: {
      type: Object,
      required: true
    },
    stations: {
      type: Array,
      required: true
    },
    geojson: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      default: 'gs-map'
    }
  },
  computed: {
    center () {
      return [
        this.location.coordinates[1],
        this.location.coordinates[0]
      ]
    }
  },
  async mounted () {
    this.layers = {
      weatherstation: L.layerGroup()
    }

    this.map = L.map(this.id, {
      attributionControl: false,
      zoomControl: false,
      center: this.center,
      dragging: false,
      keyboard: false, // Disable focus and keyboard arrows navigation
      scrollWheelZoom: false,
      doubleClickZoom: false,
      touchZoom: false
    })

    const geojsonLayer = L.geoJSON(this.geojson, {
      color: COLOR_GOLD,
      interactive: false,
      fill: false
    }).addTo(this.map)

    this.map.fitBounds(geojsonLayer.getBounds(), { maxZoom: 22 })

    this.drawLayerWeatherData()

    this.layers.weatherstation.addTo(this.map)
  },
  methods: {
    drawLayerWeatherData () {
      // first remove all elements from the weatherstation layer
      this.layers.weatherstation.clearLayers()

      // then add every station to the weatherstation layer
      this.stations.forEach((currentStation) => {
        const { geometry, properties } = currentStation
        const x = geometry.coordinates[1]
        const y = geometry.coordinates[0]
        const latLng = L.latLng(x, y)
        const icon = L.divIcon({
          html: '<span>' + properties.reference + '</span>',
          className: 'SpanBadge'
        })
        const marker = L.marker(latLng, { icon })

        this.layers.weatherstation.addLayer(marker)
      })
    }
  }
}
</script>

<style src="@/components/map/commons/map.css"></style>
<style src="@/components/map/Leaflet/Leaflet.css" ></style>

<style lang="postcss" scoped>

div[id] {
  background-color: transparent;
}

/deep/.leaflet-popup {

  &-content-wrapper {

    background-color: transparent;
    color: var(--color-font);
    font-size: .9rem;
    border-radius: unset;
    width: 14rem;
    box-shadow: unset;

    .leaflet-popup-content {
      width: 14rem !important;
      margin: 0;
    [class^="icon-wind-"], [class^="icon-ui-"] {
      font-size: 1.5rem;
      display: inline-block;
      vertical-align: middle;
    }
      .unit {
        font-style: italic;
      }
      span {
        display: inline-block;
        vertical-align: middle;
      }

    }
  }

  &-tip,&-close-button  {
    display: none;
  }

  &.left {
    .leaflet-popup-tip-container {
      transform: rotate(-90deg);
      left: unset;
      right: -30px;
      top: 16px;
    }
  }

  &.right {
    .leaflet-popup-tip-container {
      transform: rotate(90deg);
      left: -10px;
      top: 15px;
    }
  }
}
</style>
