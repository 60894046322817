import { StateWeatherStation } from './definitions'
import apiService from '@/services/api'
import { extractDataFromStationData } from './helpers'
import { DateTime } from 'luxon'
import { createStore } from 'vue-reactive-store'
import Vue from 'vue'

import { vrsStoreConfig } from '../config/store'

const state: StateWeatherStation = {
  loading: false,
  loadingLatest: false,
  error: null,
  data: null,
  dataByTimestamp: null,
  latest: []
}

const store = {
  name: 'weatherStation',
  state,
  actions: {
    async fetchWeatherStationDataLatestForOneStation (stationId: number) {
      store.state.loadingLatest = true
      try {
        const response = await apiService.getStationDataLatest(stationId)
        const data = extractDataFromStationData(response.data.data.WeatherData, stationId, vrsStoreConfig.state.data)
        const stationIndex = store.state.latest.findIndex(s => {
          return s.station === stationId
        })
        if (stationIndex !== -1) Vue.set(store.state.latest, stationIndex, data)
      } catch (e) {
        console.error(e)
        store.state.error = e
      }
      store.state.loadingLatest = false
    },
    /**
     * Fetch latest data from weather stations
     *
     * @param {array} stations
     */
    async fetchWeatherStationDataLatest (stationsId: string[] = []) {
      store.state.loadingLatest = true
      try {
        const responseWeatherStationsDataLatest = await apiService.getStationsDataLatest(stationsId)
        store.state.latest = responseWeatherStationsDataLatest
          .map(r => extractDataFromStationData(r.data.data.WeatherData, r.data.station, vrsStoreConfig.state.data))
      } catch (e) {
        console.error(e)
        store.state.error = e
      }
      store.state.loadingLatest = false
    },

    /**
     * Fetch latest data from weather stations
     * for a given date
     *
     * @param {array} stations
     */
    async fetchWeatherStationDataLatestFilteredByDate (stationsId: string[], referenceDateLuxon: DateTime) {
      store.state.loadingLatest = true
      try {
        const responseWeatherStationsDataLatest = await apiService.getStationsDataFilteredByDate(
          stationsId,
          referenceDateLuxon.minus({ minutes: 60 }),
          referenceDateLuxon
        )
        const weatherStationsDataLatest = responseWeatherStationsDataLatest
          .map(station => {
            const sortedData = station.data.sort((a, b) => (a.value_date < b.value_date ? 1 : -1))
            if (sortedData && sortedData.length > 0) {
              return { data: sortedData[0].data.WeatherData, station: sortedData[0].station }
            } else {
              return null
            }
          })
        store.state.latest = weatherStationsDataLatest.map(ws => ws && extractDataFromStationData(ws.data, ws.station, vrsStoreConfig.state.data))
      } catch (e) {
        console.error(e)
        store.state.error = e
      }
      store.state.loadingLatest = false
    },

    /**
     * Fetch latest data from weather stations
     * for a given date
     *
     * @param {array} stations
     */
    async fetchWeatherStationDataFilteredByDate (stationsId: string[], from: DateTime, to: DateTime) {
      store.state.loading = true
      try {
        const responseWeatherStationsData = await apiService.getStationsDataFilteredByDate(stationsId, from, to)

        /**
         * For each station, we browse each data and extract what we need
         * then we sort by time
         */
        const wsWorker = new Worker('@/worker/mfs.worker.ts', { type: 'module' })
        wsWorker.onmessage = function (e) {
          store.state.data = e.data.data
          store.state.dataByTimestamp = e.data.dataByTimestamp
          store.state.loading = false
          // console.log('[Store WS] WS worker is going to be terminated...')
          wsWorker.terminate()
        }
        wsWorker.postMessage({
          type: 'config',
          data: vrsStoreConfig.state.data
        })
        wsWorker.postMessage({
          type: 'weatherstation',
          data: responseWeatherStationsData
            .map(
              station => station.data.map(d => ({
                data: d.data.WeatherData,
                station: d.station
              }))
            )
        })
      } catch (e) {
        console.error(e)
        store.state.error = e
        store.state.loading = false
      }
    },

    resetState () {
      store.state.data = null
      store.state.dataByTimestamp = null
    }

  }
}

export const fetchWeatherStationDataFilteredByDate = store.actions.fetchWeatherStationDataFilteredByDate

export const fetchWeatherStationDataLatestFilteredByDate = store.actions.fetchWeatherStationDataLatestFilteredByDate

export const fetchWeatherStationDataLatest = store.actions.fetchWeatherStationDataLatest

export const fetchWeatherStationDataLatestForOneStation = store.actions.fetchWeatherStationDataLatestForOneStation

export const vrsStoreWeatherStation = createStore(store)
