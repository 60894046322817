
















































































import Vue from 'vue'

import Menu from '@/components/ui/Menu/Menu.vue'
import Loader from '@/components/ui/Loader/Loader.vue'
import Logos from '@/components/ui/Logos/Logos.vue'
import EventTime from '@/components/data/event/EventTime.vue'
import RainAlert from '@/components/data/forecast/rain/RainAlert.vue'

import { vrsStore } from '@/store'
import preferences from '@/services/preferences'
import WeatherStationAverageTemperature from '@/components/data/weatherstation/AverageTemperature/AverageTemperature.vue'
import WeatherStationAverageTemperaturePopin from '@/components/data/weatherstation/AverageTemperature/Popin.vue'
import { vrsStoreWeatherStationAverageTemperature } from '@/store/avgtemp/store'
import { vrsStoreEvent } from '@/store/event/store'
import { convertDate } from '@/helpers/dates'
import { forecastDataExist } from '@/store/event/helpers'

export default Vue.extend({
  name: 'layout-header',
  components: {
    'ui-menu': Menu,
    'ui-loader': Loader,
    'ui-logos': Logos,
    'forecast-rain-alert': RainAlert,
    'event-time': EventTime,
    WeatherStationAverageTemperature,
    WeatherStationAverageTemperaturePopin
  },
  data () {
    return {
      stateEvent: vrsStore.state.event,
      stateConfig: vrsStore.state.config,
      stateApp: vrsStore.state.app,
      stateAuth: vrsStore.state.auth,
      stateForecast: vrsStore.state.forecast,
      stateAverageTemperature: vrsStore.state.averageTemperature,
      displayAverageTemperaturePopin: false
    }
  },
  computed: {
    logos: vrsStore.modules.config.computed.logosMobile,
    displayMenuOptions: vrsStore.modules.auth.computed.displayMenuOptions,
    displayHeaderRain: vrsStore.modules.auth.computed.displayHeaderRain,
    displayHeaderAverageTemperature: vrsStore.modules.auth.computed.displayHeaderAverageTemperature,
    displayEventDateSelector: vrsStore.modules.auth.computed.displayEventDateSelector,
    avgTempDisplayData () {
      /**
       * if we are on live, we use the latest data if we have one
       */
      if (
        vrsStore.modules.app.state.data.live &&
        vrsStore.state.averageTemperature.latest
      ) {
        return {
          average: vrsStore.state.averageTemperature.latest.average,
          value_date: vrsStore.state.averageTemperature.latest.value_date
        }
      } else if (vrsStore.state.averageTemperature.data) {
        /**
         * if we are on archive mode,
         * we display the data
         */
        return {
          average: vrsStore.state.averageTemperature.data.average,
          value_date: vrsStore.state.averageTemperature.data.value_date
        }
      }
      return {
        average: null,
        value_date: null
      }
    },
    userDashboards: {
      get () {
        return this.stateAuth?.data?.user?.dashboards || []
      },
      async set (newDashboards) {
        await vrsStore.modules.auth.actions.saveDashboards(newDashboards)
      }
    }
  },
  methods: {
    async logout () {
      await vrsStore.modules.auth.actions.logout()
      this.$matomo && this.$matomo.resetUserId()
      preferences.lastURL = null
      this.$router.push('/')
    },
    changeCurrentEvent (args) {
      if (vrsStore.modules.auth.computed.canAccessToForecastClimate()) {
        if (forecastDataExist(args, this.stateEvent.events)) {
          return this.$router.push('/event/' + args + '/forecast')
        }
        return this.$router.push('/event/' + args + '/climaterecap')
      }
      this.$router.push('/event/' + args + '/forecast')
    },
    updateReferenceDate (newValue) {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          referenceDate: newValue,
          mode: 'archive'
        }
      })
      vrsStore.state.app.data.referenceDate = newValue
    },
    toggleLive () {
      // if we are live, we go to archive
      if (vrsStore.state.app.data.live) {
        // go to archive mode
        this.$router.push({
          path: this.$route.path,
          query: {
            referenceDate: vrsStore.state.app.data.referenceDate
          }
        })
      } else {
        // go live
        this.$router.push({ path: this.$route.path })
      }
    },
    toggleAverageTemperaturePopin () {
      this.displayAverageTemperaturePopin = !this.displayAverageTemperaturePopin
    },
    onExportAvgTemp ({ to, from }: { to: string; from: string}) {
      vrsStoreWeatherStationAverageTemperature.actions.exportCSV(
        vrsStoreEvent.state.data.id,
        convertDate(from + '000000'),
        convertDate(to + '235959')
      )
    },
    /**
     * Create a new dashboard page,
     * then go directly to it in edition mode
     */
    addDashboardPage () {
      vrsStore.state.auth.data.user.dashboards.push({
        label: 'New dashboard',
        layout: []
      })
      this.$router.push('/event/' + (this.eventId || 'active') + '/dashboard/' + (vrsStore.state.auth.data.user.dashboards.length - 1) + '?edit=true')
    },

    async updateDashboardLabel (dashboardItem) {
      const dashboards = [
        ...vrsStore.modules.auth.state.data.user.dashboards
      ]
      dashboards[dashboardItem.id].label = dashboardItem.label
      await vrsStore.modules.auth.actions.saveDashboards(dashboards)
    },
    async removeDashboard (dashboardIndex) {
      const dashboards = [
        ...vrsStore.modules.auth.state.data.user.dashboards
      ]
      dashboards.splice(dashboardIndex, 1)
      await vrsStore.modules.auth.actions.saveDashboards(dashboards)
      this.$router.push(vrsStore.modules.auth.computed.defaultHomePage())
    }
  }
})
