module.exports = {
  'color-red': process.env.VUE_APP_STYLES_COLOR_RED,
  'color-orange': process.env.VUE_APP_STYLES_COLOR_ORANGE,
  'color-green': process.env.VUE_APP_STYLES_COLOR_GREEN,
  'color-yellow': process.env.VUE_APP_STYLES_COLOR_YELLOW,
  'color-gray': process.env.VUE_APP_STYLES_COLOR_GRAY,
  'color-white': process.env.VUE_APP_STYLES_COLOR_WHITE,

  circleOverLimitColor: process.env.VUE_APP_STYLES_COLOR_CIRCLE_OVER_LIMIT,

  'default-spacing': '0.6rem',
  'border-radius': '2px',

  'breakpoint-xxs': '640px',
  'breakpoint-xs': '768px',
  'breakpoint-sm': '960px',
  'breakpoint-md': '1264px',
  'breakpoint-lg': '1367px',

  'header-height': '4.5rem',

  forecastWidth: '6rem',
  forecastWidthLarge: '8rem',
  forecastWidthLegend: '8rem',
  offsetLeftTemperatureLineOccurence: '7',

  OneHourSize: '41em',

  OneDaySize__previousNight: '3em',
  OneDaySize__weatherConditions: '3em',

  OneHourSize__hour: '1.5em',
  OneHourSize__session: '2em',
  OneHourSize__weather: '4em',
  OneHourSize__chance: '3.5em',
  OneHourSize__temperature: '8em',
  OneHourSize__track: '2em',
  OneHourSize__wind: '3em',
  OneHourSize__average: '3em',
  OneHourSize__humidity: '3em',
  OneHourSize__pressure: '2em',

  OffsetBottomTemperatureLine__default: '12.5em',
  OffsetBottomTemperatureLine__mobile: '12.5em',

  timelineHeight: 48,
  timelineHeightPixel: '64px'
}
