var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-center items-center border-l border-solid px-2 overflow-hidden",class:[{
    'top-18': _vm.isOpen
  }, _vm.bgClass],staticStyle:{"border-color":"var(--border-color)"}},[(_vm.loading)?_c('ui-loader',{attrs:{"variant":_vm.rain.status === undefined ? 'font': 'dark'}}):_vm._e(),(_vm.rain)?_c('button',{staticClass:"h-full w-full sm:w-auto text-5xl lg:text-5xl flex items-center justify-center sm:pr-4",on:{"click":_vm.toggleDetails}},[(_vm.rain.status === 1 || _vm.rain.status === 2)?_c('span',{staticClass:"icon-weather-12"}):(_vm.rain.status === 3)?_c('span',{staticClass:"icon-drops-3"}):_c('span',{staticClass:"icon-info"},[_vm._v("ⓘ")])]):_vm._e(),_c('div',{staticClass:"absolute left-0 right-0 pt-4 px-4 transition duration-200 sm:flex sm:static sm:p-0 flex-col",class:[{
      'hidden': !_vm.isOpen,
      'top-18': _vm.isOpen,
      '-top-100': !_vm.isOpen,
    }, _vm.bgClass]},[(_vm.rain && _vm.rain.updateTime)?_c('span',{staticClass:"sm:text-sm italic"},[_vm._v("Updated: "+_vm._s(_vm.rain.updateTime))]):_vm._e(),(_vm.rain && _vm.rain.updateTime)?_c('div',{staticClass:"text-lg md:text-xl lg:text-2xl"},[(_vm.rain.status === 0)?_c('span',[_vm._v("No rain expected")]):(_vm.rain.status === 1)?_c('span',[_vm._v("Rain expected at "+_vm._s(_vm.rain.beginning))]):(_vm.rain.status === 2)?_c('span',[_vm._v("It's raining")]):(_vm.rain.status === 3)?_c('span',[_vm._v("Drops at times")]):_vm._e()]):_vm._e(),(_vm.rain && _vm.rain.isMessage === true)?_c('span',{staticClass:"text-base lg:text-lg"},[_vm._v(_vm._s(_vm.rain.message))]):_vm._e(),(!_vm.loading && (!_vm.rain || (_vm.rain && !_vm.rain.updateTime)))?_c('p',[_vm._v(" "+_vm._s(_vm.defaultAlertInfo)+" ")]):_vm._e(),_c('forecast-rain-information',_vm._b({staticClass:"sm:hidden p-4 -mx-4 mt-4 bg-primary-dark text-font border-b border-solid border-gray",class:{
        'hidden': !_vm.isOpen,
      },attrs:{"default-alert-info":_vm.defaultAlertInfo,"is-in-header":true}},'forecast-rain-information',_vm.rain,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }