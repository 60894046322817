

































































import Vue from 'vue'
import Loader from '@/components/ui/Loader/Loader.vue'
import RainInformation from './RainInformation.vue'

export default Vue.extend({
  name: 'rain-alert',
  components: {
    'ui-loader': Loader,
    'forecast-rain-information': RainInformation
  },
  props: {
    defaultAlertInfo: String,
    loading: Boolean,
    rain: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    bgClass () {
      let cssClass = ''
      if (!this.rain) return cssClass
      switch (this.rain?.status) {
        case 0:
          cssClass += 'bg-green '
          break
        case 1:
          cssClass += 'bg-orange '
          break
        case 2:
          cssClass += 'bg-red '
          break
        case 3:
          cssClass += 'bg-yellow '
          break
      }
      if ([0, 1, 2, 3].indexOf(this.rain?.status) >= 0) {
        cssClass += 'text-primary-dark '
      } else {
        cssClass += 'bg-background '
      }
      return cssClass
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleDetails () {
      this.isOpen = !this.isOpen
    }
  }
})

