function getHeight (
  value,
  min,
  max,
  ecart,
  graphHeight,
  graphHeader
) {
  let height
  if (value === max && value === max) {
    height = graphHeight - ((graphHeight / ecart) * (value - min)) + graphHeader
  } else if (value === max) {
    height = graphHeader
  } else {
    height = graphHeight - ((graphHeight / ecart) * (value - min)) + graphHeader
  }
  return height
}

function getBezier1 (index, currentNote, previousNote, graphHeight, graphHeader, min, max, ecart, spaceBetweenNotes) {
  const x = spaceBetweenNotes * (index - 0.75)
  const y = getHeight(previousNote, min, max, ecart, graphHeight, graphHeader)
  return x + ',' + y + ' '
}

function getBezier2 (index, currentNote, previousNote, graphHeight, graphHeader, min, max, ecart, spaceBetweenNotes) {
  const x = spaceBetweenNotes * (index - 0.25)
  const y = getHeight(currentNote, min, max, ecart, graphHeight, graphHeader)
  return x + ',' + y + ' '
}

function getPoint (index, currentNote, graphHeight, graphHeader, min, max, ecart, spaceBetweenNotes) {
  const x = spaceBetweenNotes * index
  const y = getHeight(currentNote, min, max, ecart, graphHeight, graphHeader)
  return { x, y }
}

/**
 * Return two paths and array of points to draw a plot linear
 * Integrate bezier curves, and a gradient path
 * Style 3: Graph with marge and visible line before the first circle and after the last circle
 * (marge === spaceBetweenNotes)
 *
 * @param {number} graphHeight
 * height of the graph, including graphHeader but not offsetHeight
 * width of the graph, not including offsetWidth
 * @param {array} notes
 * notes to display on the path
 * @param min
 * @param max
 * @param ecart
 * @param {number} spaceBetweenNotes
 * space between two notes
 * @param {number} graphHeader
 * space inside the graph to be used for specific shapes, eg circles that could be above the path
 * maximum value for a statistic
 * @param temperatureOverLimit
 * @returns {{path: string, circles: *[]}}
 */
function getPathTemperature (graphHeight, notes, min, max, ecart, graphHeader, spaceBetweenNotes, temperatureOverLimit = null) {
  let path = ''; let previousNote = notes[0]

  const circles = []

  for (let index = 0; index < notes.length; index++) {
    const currentNote = notes[index]
    if ((!(notes[index + 1] > currentNote)) ||
      (!(notes[index + 1] < currentNote)) ||
      previousNote === currentNote) {
      const currentPoint = getPoint(index, currentNote, graphHeight, graphHeader, min, max, ecart, spaceBetweenNotes)
      circles.push({
        x: currentPoint.x,
        y: currentPoint.y,
        isOverLimit: temperatureOverLimit !== null && currentNote ? currentNote >= temperatureOverLimit : false,
        text: {
          note: currentNote,
          x: (currentNote >= 10
            ? currentPoint.x - 8
            : currentPoint.x - 4
          ),
          y: currentPoint.y + 4
        }
      })
      if (previousNote !== currentNote) {
        path += 'C' +
          getBezier1(index, currentNote, previousNote, graphHeight, graphHeader, min, max, ecart, spaceBetweenNotes) +
          getBezier2(index, currentNote, previousNote, graphHeight, graphHeader, min, max, ecart, spaceBetweenNotes)
      } else {
        path += 'L'
      }
      path += currentPoint.x + ',' + currentPoint.y + ' '
      previousNote = currentNote
    }
  }

  return { path, circles }
}

/**
 * Return Path with circle for fine graph line
 * Style 1: Graph depends on the size of the notes (Line === Number note)
 *
 * @param {number} graphHeight
 * height of the graph, including graphHeader but not offsetHeight
 * width of the graph, not including offsetWidth
 * @param {array} notes
 * notes to display on the path
 * @param min
 * @param max
 * @param ecart
 * @param {number} spaceBetweenNotes
 * space between two notes
 * @param {number} graphHeader
 * space inside the graph to be used for specific shapes, eg circles that could be above the path
 * maximum value for a statistic
 * @param {boolean} isTemp
 * to handle position in circle
 * @returns {{path: string, circles: *[]}}
 */
function getPathBetweenData (graphHeight, notes, min, max, ecart, graphHeader, spaceBetweenNotes, isTemp = false) {
  let path = ''
  let previousNote = notes[0]
  const circles = []
  for (let index = 0; index < notes.length; index++) {
    const currentNote = notes[index]
    const currentPoint = getPoint(index, currentNote, graphHeight, graphHeader, min, max, ecart, spaceBetweenNotes)
    if (isTemp) {
      circles.push({
        x: currentPoint.x,
        y: currentPoint.y,
        text: {
          note: currentNote,
          x: (currentNote >= 10
            ? currentPoint.x - 8
            : currentPoint.x - 4
          ),
          y: currentPoint.y + 4
        }
      })
    } else {
      circles.push({
        x: currentPoint.x,
        y: currentPoint.y,
        text: {
          note: currentNote,
          x: (currentNote >= 10
            ? currentPoint.x - 6
            : currentPoint.x - 4
          ),
          y: (currentPoint.y >= 18
            ? currentPoint.y + 2
            : currentPoint.y + 28
          )
        }
      })
    }

    // Directly begin with the first value of note
    if (index === 0) {
      path = 'M' + currentPoint.x + ',' + currentPoint.y + ' '
    } else {
      if (previousNote !== currentNote) {
        path += 'C' +
          getBezier1(index, currentNote, previousNote, graphHeight, graphHeader, min, max, ecart, spaceBetweenNotes) +
          getBezier2(index, currentNote, previousNote, graphHeight, graphHeader, min, max, ecart, spaceBetweenNotes)
      } else {
        path += 'L'
      }
      path += currentPoint.x + ',' + currentPoint.y + ' '
    }
    previousNote = currentNote
  }
  return { path, circles }
}

/**
 * Return Path with circle for fine graph line
 * Style 2: Graph with marge before the first circle and after the last circle (marge === spaceBetweenNotes)
 *
 * @param {number} graphHeight
 * height of the graph, including graphHeader but not offsetHeight
 * width of the graph, not including offsetWidth
 * @param {array} notes
 * notes to display on the path
 * @param min
 * @param max
 * @param ecart
 * @param {number} spaceBetweenNotes
 * space between two notes
 * @param {number} graphHeader
 * space inside the graph to be used for specific shapes, eg circles that could be above the path
 * maximum value for a statistic
 * @returns {{path: string, circles: *[]}}
 */
function getPathLineWithExtraData (graphHeight, notes, min, max, ecart, graphHeader, spaceBetweenNotes) {
  let path = ''
  let previousNote = notes[0]
  const circles = []
  for (let index = 0; index < notes.length; index++) {
    const currentNote = notes[index]
    if ((!(notes[index + 1] > currentNote)) ||
      (!(notes[index + 1] < currentNote)) ||
      previousNote === currentNote) {
      const currentPoint = getPoint(index, currentNote, graphHeight, graphHeader, min, max, ecart, spaceBetweenNotes)
      circles.push({
        x: currentPoint.x,
        y: currentPoint.y,
        text: {
          note: currentNote,
          x: (currentNote >= 10
            ? currentPoint.x - 6
            : currentPoint.x - 4
          ),
          y: (currentPoint.y >= 18
            ? currentPoint.y + 2
            : currentPoint.y + 28
          )
        }
      })

      // Directly begin with the first value of note
      if (index === 1) {
        path = 'M'
      } else if (index > 1) {
        // Compute Bezier Curve
        if (index !== notes.length) {
          if (previousNote !== currentNote) {
            path += 'C' +
              getBezier1(index, currentNote, previousNote, graphHeight, graphHeader, min, max, ecart, spaceBetweenNotes) +
              getBezier2(index, currentNote, previousNote, graphHeight, graphHeader, min, max, ecart, spaceBetweenNotes)
          } else {
            path += 'L'
          }
        }
      }
      path += currentPoint.x + ',' + currentPoint.y + ' '
      previousNote = currentNote
    }
  }
  return { path, circles }
}

/**
 * Return Path with circle for fine graph line
 *
 * @export
 * @param {number} graphHeight
 * height of the graph, including graphHeader but not offsetHeight
 * @param {number} graphWidth
 * width of the graph, not including offsetWidth
 * @param {array} notes
 * notes to display on the path
 * @param {number} offsetHeight
 * height below the graph, used for the linear gradient fill
 * @param {number} offsetWidth
 * width at left / right of the graph, used to draw a path longer than just the notes
 * @param {number} spaceBetweenNotes
 * space between two notes
 * @param {number} graphHeader
 * space inside the graph to be used for specific shapes, eg circles that could be above the path
 * @param {null|number} statOverLimit
 * maximum value for a statistic
 * @param isStrictLineBetweenCircle
 * Style of graph
 * @returns {{gradient: string, circles: *[], main: string}}
 */
export function getPaths (
  graphHeight,
  graphWidth,
  notes,
  offsetHeight,
  offsetWidth,
  spaceBetweenNotes,
  graphHeader,
  isStrictLineBetweenCircle,
  statOverLimit = null
) {
  const startWidth = offsetWidth / 2
  const min = Math.min(...notes)
  const max = Math.max(...notes)
  const ecart = (max - min === 0 ? 1 : max - min)
  const initialPointHeight = getHeight(min, min, max, ecart, graphHeight, graphHeader)

  if (!isStrictLineBetweenCircle) {
    const pointsToDisplay = [min].concat(notes).concat([min])
    const { path, circles } = getPathTemperature(graphHeight, pointsToDisplay, min, max, ecart, graphHeader, spaceBetweenNotes, statOverLimit)
    const main = 'M-' + startWidth + ',' + initialPointHeight +
      ' ' + path +
      ' L' + (graphWidth + offsetWidth / 2) + ',' + initialPointHeight

    const gradient = 'M-' + startWidth + ',' + (graphHeight + offsetHeight + graphHeader) +
      ' L-' + startWidth + ',' + initialPointHeight +
      ' ' + path +
      ' L' + (graphWidth + offsetWidth / 2) + ',' + initialPointHeight +
      ' L' + (graphWidth + offsetWidth / 2) + ',' + (graphHeight + offsetHeight + graphHeader) +
      ' Z'
    return {
      main,
      gradient,
      circles: circles.filter((currentCircle, index) => (index !== 0 && index !== circles.length - 1))
    }
  } else {
    const { path, circles } = getPathBetweenData(graphHeight, notes, min, max, ecart, graphHeader, spaceBetweenNotes, true)
    const defaultHeightGradient = (graphHeight + offsetHeight + graphHeader)
    const lastCoordinate = path.trim().split(' ').pop()
    const coord = lastCoordinate.split(',')
    // L should only be added if the last coordinate is identical to the previous one, i.e. not approximated by Bezier
    const last = lastCoordinate.startsWith('L') ? `${coord[0]},${parseFloat(coord[1]) + (graphHeader + offsetHeight)}` : `L${coord[0]},${parseFloat(coord[1]) + (graphHeader + offsetHeight)}`
    const gradient = 'M' + 0 + ',' + defaultHeightGradient +
      ' ' + 'L' + path.slice(1) + last + ' Z'
    return {
      main: path,
      gradient,
      circles
    }
  }
}

/**
 * Return two paths and array of points to draw a plot linear
 * Integrate bezier curves
 *
 * @export
 * @param {number} graphHeight
 * height of the graph, including graphHeader but not offsetHeight
 * width of the graph, not including offsetWidth
 * @param {array} notes
 * notes to display on the path
 * height below the graph, used for the linear gradient fill
 * width at left / right of the graph, used to draw a path longer than just the notes
 * @param {number} spaceBetweenNotes
 * space between two notes
 * @param {number} graphHeader
 * space inside the graph to be used for specific shapes, eg circles that could be above the path
 * maximum value for a statistic
 * @param isStrictLineBetweenCircle
 * Style of graph
 * @returns {{circles: *[], main: string}}
 */
export function getPathsLine (
  graphHeight,
  notes,
  spaceBetweenNotes,
  graphHeader,
  isStrictLineBetweenCircle
) {
  const min = Math.min(...notes)
  const max = Math.max(...notes)
  const ecart = (max - min === 0 ? 1 : max - min)
  if (isStrictLineBetweenCircle) {
    const { path, circles } = getPathBetweenData(graphHeight, notes, min, max, ecart, graphHeader, spaceBetweenNotes)
    const main = path
    return {
      main,
      circles
    }
  } else {
    const pointsToDisplay = [min].concat(notes)
    const { path, circles } = getPathLineWithExtraData(graphHeight, pointsToDisplay, min, max, ecart, graphHeader, spaceBetweenNotes)
    const main = path
    return {
      main,
      circles: circles.filter((currentCircle, index) => index !== 0)
    }
  }
}

/**
 * Return rect to draw a histogram
 *
 * @export
 * @param {number} graphHeight
 * height of the graph, including graphHeader but not offsetHeight
 * width of the graph, not including offsetWidth
 * @param {array} notes
 * notes to display on the path
 * @param {number} spaceBetweenNotes
 * space between two notes
 * @param {number} graphHeader
 * space inside the graph to be used for specific shapes, eg circles that could be above the path
 * @returns
 */
export function getRects (
  graphHeight,
  notes,
  spaceBetweenNotes,
  graphHeader
) {
  const min = Math.min(...notes)
  const max = Math.max(...notes)
  const ecart = (max - min === 0 ? 1 : max - min)
  const main = []
  for (let index = 0; index < notes.length; index++) {
    const currentNote = notes[index]
    const currentPoint = getPoint(index, currentNote, graphHeight, graphHeader, min, max, ecart, spaceBetweenNotes)
    const height = getHeight(currentNote, min, max, ecart, graphHeight, graphHeader)
    const rect = {
      height,
      x: currentPoint.x,
      y: currentPoint.y
    }
    const text = {
      x: rect.x + 8,
      y: rect.y - 4,
      value: currentNote
    }
    main.push({
      rect,
      text
    })
  }

  return {
    main
  }
}
