import { DateTime } from 'luxon'
import { WeatherData, WeatherStationData } from './definitions'
import { stringifyDate } from '@/helpers/dates'

const NO_DATA = 'nodata'

export function convertWindDirectionToOrientation (direction) {
  // first, adapt the direction to a specific value
  const sixteenRoseIteration = 22.5
  const directionIterationsRounded = Math.round(direction / sixteenRoseIteration)
  const currentDirectionInSixteenRose = Math.trunc(directionIterationsRounded * sixteenRoseIteration)

  const conversionTable = []
  conversionTable['0'] = 'n'
  conversionTable['22'] = 'nne'
  conversionTable['45'] = 'ne'
  conversionTable['67'] = 'ene'
  conversionTable['90'] = 'e'
  conversionTable['112'] = 'ese'
  conversionTable['135'] = 'se'
  conversionTable['157'] = 'sse'
  conversionTable['180'] = 's'
  conversionTable['202'] = 'sso'
  conversionTable['225'] = 'so'
  conversionTable['247'] = 'oso'
  conversionTable['270'] = 'o'
  conversionTable['292'] = 'ono'
  conversionTable['315'] = 'no'
  conversionTable['337'] = 'nno'
  conversionTable['360'] = 'n'

  return conversionTable[currentDirectionInSixteenRose]
}

/**
 * Extract the data from the API data
 * for computing purpose
 *
 * @param stationData
 * Station data, from the XML file in the API side
 *
 * @param stationId
 * Database id of the station, not the 1 / 2 / 3 id
 *
 * @returns
 * Weather station data adapted for computing
 */

export function extractSimpleDataFromStationData (stationData: WeatherData, stationId: number): WeatherStationData {
  if (!stationData) return null
  const data: any = {}
  const readings = stationData.Readings
  const airTemperature = readings.AirTemp['@value']
  const trackTemperature = readings.TrackTemp['@value']

  data.station = stationId
  data.stationId = stationData['@idStation']
  data.airTemperature = (
    airTemperature === NO_DATA
      ? '--'
      : Math.round(parseFloat(readings.AirTemp['@value'] as string) * 10) / 10 // °C
  )
  data.trackTemperature = (
    trackTemperature === NO_DATA
      ? '--'
      : Math.round(parseFloat(readings.TrackTemp['@value'] as string) * 10) / 10 // °C
  )

  let gusts = readings.Gusts['@value'] // m/s
  gusts = (
    gusts === NO_DATA
      ? '--'
      : Math.round(parseFloat(gusts as string) * 60 * 60 / 100) / 10 // kph
  )
  data.gusts = gusts // km/h

  let humidity = readings.Humidity['@value']
  humidity = (
    humidity === NO_DATA
      ? '--'
      : Math.round(humidity as number)
  )
  data.humidity = humidity

  let pressure = readings.Pressure['@value']
  pressure = (
    pressure === NO_DATA
      ? '--'
      : Math.round(parseFloat(pressure as string) * 10) / 10 // hPa
  )
  data.pressure = pressure

  const rainIntensity = readings.RainIntensity['@value'] // mm/h
  data.rainIntensity = (
    rainIntensity === NO_DATA
      ? '--'
      : rainIntensity
  )
  if (rainIntensity === NO_DATA) {
    data.rainIntensityText = '--'
  } else if (rainIntensity === 0) {
    data.rainIntensityText = 'dry'
  } else if (rainIntensity < 0.2) {
    data.rainIntensityText = 'few drops'
  } else {
    data.rainIntensityText = 'rain'
  }

  const windDirection = readings.WindDirection['@value'] // °
  data.windDirection = convertWindDirectionToOrientation(windDirection)
  data.windDirectionDegree = windDirection

  let windSpeed = readings.WindSpeed['@value'] // m/s
  windSpeed = (
    windSpeed === NO_DATA
      ? '--'
      : Math.round(parseFloat(windSpeed as string) * 60 * 60 / 100) / 10 // kph
  )
  data.windSpeed = windSpeed // km/h

  return data
}

/**
 * Extract the data from the API data
 * for displaying purpose
 *
 * @param stationData
 * Station data, from the XML file in the API side
 *
 * @param stationId
 * Database id of the station, not the 1 / 2 / 3 id
 *
 * @param config
 * API Configuration for defining limits for temperature, wind speed, etc.
 *
 * @returns
 * Weather station data adapted for display
 */
export function extractDataFromStationData (stationData: WeatherData, stationId: number, config): WeatherStationData {
  if (!stationData) return null

  const data: any = extractSimpleDataFromStationData(stationData, stationId)
  const time = DateTime.fromFormat(stationData['@ReadingTimestamp'], 'yyyy-MM-dd\'T\'HH:mm:ss')

  data.time = time
  data.timestamp = parseInt(stringifyDate(time))
  data.timeFormatted = time.toFormat('yyyy-MM-dd\'T\'HH:mm:ss')
  data.timeToDisplay = time.toFormat('T')

  data.isAirTemperatureOverLimit = data.airTemperature >= config.LIMIT_WSDATA_TEMPERATURE_AIR
  data.isTrackTemperatureOverLimit = data.trackTemperature >= config.LIMIT_WSDATA_TEMPERATURE_TRACK
  data.isGustsOverLimit = data.gusts >= config.LIMIT_WSDATA_WIND_GUSTS
  data.isHumidityOverLimit = data.humidity >= config.LIMIT_WSDATA_HUMIDITY
  data.isPressureOverLimit = data.pressure >= config.LIMIT_WSDATA_PRESSURE
  data.isRainIntensityOverLimit = data.rainIntensity >= config.LIMIT_WSDATA_RAIN
  data.isWindSpeedOverLimit = data.windSpeed >= config.LIMIT_WSDATA_WIND_AVERAGE

  return data
}
