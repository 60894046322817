
































































































































































import Draggable from 'vuedraggable'
import ArchiveSelect from './ArchiveSelect.vue'
import ActiveEvent from './ActiveEvent.vue'
import Button from '@/components/ui/Button/Button.vue'

export default {
  name: 'Menu',
  components: {
    ArchiveSelect,
    ActiveEvent,
    'ui-button': Button,
    'vd-draggable': Draggable
  },
  props: {
    eventId: {
      type: [Number, String]
    },
    seasons: Array,
    activeEvent: Object,
    config: Object,
    displayMenuOptions: {
      type: Object,
      required: false,
      default: () => ({})
    },
    dashboards: {
      type: Array,
      required: false
    }
  },
  data: () => ({
    isOpened: false,
    version: MFS_APP_VERSION,
    dashboardItemUpdating: null
  }),
  computed: {
    routes () {
      const result = []
      if (this.displayMenuOptions.climateRecap) {
        result.push({
          to: {
            path: '/event/' + (this.eventId || 'active') + '/climaterecap',
            query: this.$route.query
          },
          label: 'Climate recap',
          name: 'climaterecap'
        })
      }
      if (this.displayMenuOptions.forecast) {
        result.push({
          to: {
            path: '/event/' + (this.eventId || 'active') + '/forecast',
            query: this.$route.query
          },
          label: 'Forecast',
          name: 'forecast'
        })
      }
      if (this.displayMenuOptions.nowcasting) {
        result.push({
          to: {
            path: '/event/' + (this.eventId || 'active') + '/nowcasting',
            query: this.$route.query
          },
          label: 'Nowcasting',
          name: 'nowcasting'
        })
      }
      if (this.displayMenuOptions.observations) {
        result.push({
          to: {
            path: '/event/' + (this.eventId || 'active') + '/observations',
            query: this.$route.query
          },
          label: 'Observations',
          name: 'observations'
        })
      }
      if (this.displayMenuOptions.dashboard) {
        result.push({
          to: '/dashboard/' + (this.eventId || 'active'),
          label: 'Dashboard',
          name: 'dashboard'
        })
      }
      if (this.displayMenuOptions.messages) {
        // if we are "in dashboard" (ex 'media' mode)
        // messages will be displayed in mobile mode, so we add a class
        if (
          this.displayMenuOptions.dashboard &&
          !this.displayMenuOptions.forecast
        ) {
          result.push({
            to: '/messages',
            label: 'Message',
            class: 'hidden md:block' // we hide this option if we are on mobile, because dashboard include the messages
          })
        } else {
          result.push({
            to: {
              path: '/event/' + (this.eventId || 'active') + '/message',
              query: this.$route.query
            },
            label: 'Message',
            name: 'message'
          })
        }
      }
      if (this.displayMenuOptions.archive) {
        result.push({
          to: {
            path: '/map'
          },
          label: 'Events map',
          name: 'map'
        })
      }
      return result
    },
    computedDashboards: {
      get () {
        if (!this.dashboards) return []
        return this.dashboards.map((currentDashboard: { label: string }, index: number) => {
          // we remove the edit query param if present
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { edit, ...otherQueryParams } = this.$route.query
          return {
            // label: currentDashboard.label,
            ...currentDashboard,
            draggableData: {
              id: index,
              to: {
                path: '/event/' + (this.eventId || 'active') + '/dashboard/' + index,
                query: otherQueryParams
              },
              class: 'user-page'
            }
          }
        })
      },
      set (newValue: {draggableData: object; label: string; layout: []}[]) {
        // remove all local stuff to not be saved
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.$emit('update:dashboards', newValue.map(({ draggableData, ...dashboardConfig }) => {
          return dashboardConfig
        }))
      }
    }
  },
  methods: {
    toggleMenu () {
      this.isOpened = !this.isOpened
    },
    emitSelectEvent (id: number) {
      this.$emit('select-event', id)
      this.isOpened = false
    },
    openLink (link: string) {
      window.open(link)
      return false
    },
    addDashboardPage () {
      this.isOpened = false
      this.$emit('add-dashboard-page')
    },
    updateDashboardLabel () {
      this.$emit('update:dashboard-label', { id: this.dashboardItemUpdating.draggableData.id, label: this.dashboardItemUpdating.label })
      this.dashboardItemUpdating = null
    },
    removeDashboard () {
      this.$emit('remove:dashboard', this.dashboardItemUpdating.draggableData.id)
      this.dashboardItemUpdating = null
    }
  }
}
