import axios, { AxiosInstance, AxiosResponse, AxiosPromise } from 'axios'
import preferenceService from './preferences'
import { DateTime } from 'luxon'
import { SignUpParameters } from '@/store/auth/definitions'
import { DRFMessageData } from '@/store/message/definitions'
import { WeatherStationDataFromAPI } from '@/store/weatherstation/definitions'
import { AverageTemperatureDataFromAPI } from '@/store/avgtemp/definitions'

export const SIGNATURE_HAS_EXPIRED = 'Signature has expired.'
export const ERROR_DECODING_SIGNATURE = 'Error decoding signature.'

function addTokenToHeader (config) {
  const token = preferenceService.token
  if (token) config.headers.Authorization = 'JWT ' + token
  return config
}

axios.defaults.headers.common['X-Requested-With'] = MFS_APP_VERSION

class ApiService {
  /**
   * Useful to know if a refresh token is in progress.
   *
   * If it is, we must refresh only one time.
   *
   * If the result is an error,
   * we must return the error to the caller.
   */
  private _refreshingToken = false
  private _instance: AxiosInstance
  private _interceptorTokenID: number

  constructor () {
    this._instance = axios.create({
      baseURL: SITE_API_URL
    })

    this._interceptorTokenID = this._instance.interceptors.request.use(addTokenToHeader)
  }

  setErrorHandler (errorHandler) {
    if (errorHandler) {
      this._instance.interceptors.response.use(
        function (response) {
          return response
        },
        function (error) {
          errorHandler(error)
          return Promise.reject(error)
        }
      )
    }
  }

  /**
   * Token endpoints
   */
  refreshToken (token) {
    return this._instance.request({
      method: 'post',
      url: 'refresh/',
      data: {
        token
      }
    })
  }

  /**
   * User endpoints
   */
  login (username: string, password: string): Promise<AxiosResponse> {
    return this._instance.request({
      method: 'post',
      url: 'login/',
      data: {
        username,
        password
      }
    })
  }

  logout () {
    return this._instance
      .request({
        method: 'post',
        url: 'logout/'
      })
  }

  /**
   * Config endpoint
   */
  getConfig () {
    this._instance.interceptors.request.eject(this._interceptorTokenID)
    const request = this._instance.request({
      method: 'get',
      url: 'config/'
    })
    this._interceptorTokenID = this._instance.interceptors.request.use(addTokenToHeader)
    return request
  }

  /**
   * Events endpoints
   */
  getActiveEvent () {
    return this._instance.request({
      method: 'get',
      url: 'events/active/'
    })
  }

  getEvent (eventId: number | string) {
    return this._instance.request({
      method: 'get',
      url: 'events/' + eventId + '/'
    })
  }

  getEvents () {
    return this._instance.request({
      method: 'get',
      url: 'events/'
    })
  }

  getEventsByCode (code: string) {
    return this._instance.request({
      method: 'get',
      url: `events/?code=${code}`
    })
  }

  /**
   * Forecast endpoints
   */
  getForecastDaily (eventId) {
    return this._instance.request({
      method: 'get',
      url: 'events/' + eventId + '/forecast/daily/'
    })
  }

  getForecastHourly (eventId) {
    return this._instance.request({
      method: 'get',
      url: 'events/' + eventId + '/forecast/hourly/'
    })
  }

  getForecastRain (eventId) {
    return this._instance.request({
      method: 'get',
      url: 'events/' + eventId + '/forecast/rain/'
    })
  }

  /**
   * Weather station endpoints
   */
  getStationDataLatest (id: number): AxiosPromise<WeatherStationDataFromAPI> {
    return this._instance.request({
      method: 'get',
      url: 'weatherstation/' + id + '/latest/'
    })
  }

  getStationsDataLatest (stationsId: string[]): Promise<AxiosResponse<WeatherStationDataFromAPI>[]> {
    return axios.all(
      stationsId.map(
        stationId => this.getStationDataLatest(parseInt(stationId))
      )
    )
  }

  getStationDataFilteredByDate (id: string, dateFrom: DateTime, dateTo: DateTime): AxiosPromise<WeatherStationDataFromAPI[]> {
    return this._instance.request({
      method: 'get',
      url: 'weatherstation/' + id + '/datas/',
      params: {
        date_from: dateFrom.toFormat('yyyy-MM-dd\'T\'HH:mm:ss'),
        date_to: dateTo.toFormat('yyyy-MM-dd\'T\'HH:mm:ss')
      }
    })
  }

  getStationsDataFilteredByDate (stationsId: string[], dateFrom: DateTime, dateTo: DateTime): Promise<AxiosResponse<WeatherStationDataFromAPI[]>[]> {
    return axios.all(
      stationsId.map(
        stationId => this.getStationDataFilteredByDate(stationId, dateFrom, dateTo)
      )
    )
  }

  getAverageTemperatureLatest (eventId: number): Promise<AxiosResponse<AverageTemperatureDataFromAPI>> {
    return this._instance.request({
      method: 'get',
      url: 'events/' + eventId + '/avgtemp/latest/'
    })
  }

  getAverageTemperatureFilteredByDate (eventId: number, dateFrom: DateTime, dateTo: DateTime): Promise<AxiosResponse<AverageTemperatureDataFromAPI[]>> {
    return this._instance.request({
      method: 'get',
      url: 'events/' + eventId + '/avgtemp/',
      params: {
        date_from: dateFrom.toFormat('yyyy-MM-dd\'T\'HH:mm:ss'),
        date_to: dateTo.toFormat('yyyy-MM-dd\'T\'HH:mm:ss')
      }
    })
  }

  /**
   * Radar endpoints
   */
  getRadarAvailableTimesLatest (eventId: number) {
    return this._instance
      .request({ method: 'get', url: 'events/' + eventId + '/radar/latest/' })
      .catch(() => {
        return { data: null }
      })
  }

  getRadarBbox (eventId: number) {
    return this._instance
      .request({ method: 'get', url: 'events/' + eventId + '/radar/bbox/' })
      .catch(() => {
        return { data: null }
      })
  }

  getAllWeatherStationDataForEventSessions (eventId: number) {
    return this._instance
      .request({
        method: 'get',
        url: 'events/' + eventId + '/stationdata/allsessions/'
      })
  }

  /**
   * Messages endpoint
   */
  getMessages (eventId): AxiosPromise<DRFMessageData[]> {
    return this._instance.request({
      method: 'get',
      url: 'events/' + eventId + '/messages/'
    })
  }

  /**
   * Infos endpoint
   */
  getInfos (eventId) {
    return this._instance.request({
      method: 'get',
      url: 'events/' + eventId + '/infos/'
    })
  }

  getStatistics (dateFrom: DateTime, dateTo: DateTime) {
    return this._instance.request({
      method: 'get',
      url: 'statistics/',
      params: {
        date_from: dateFrom.toFormat('yyyy-MM-dd\'T\'HH:mm:ss'),
        date_to: dateTo.toFormat('yyyy-MM-dd\'T\'HH:mm:ss'),
        limit: 100000
      }
    })
  }

  getUser () {
    return this._instance.request({
      method: 'get',
      url: 'me/'
    })
  }

  updateUser (data) {
    return this._instance.request({
      method: 'put',
      url: 'me/',
      data
    })
  }

  patchUser (data) {
    return this._instance.request({
      method: 'patch',
      url: 'me/',
      data
    })
  }

  signUp (data: SignUpParameters) {
    return this._instance.request({
      method: 'post',
      url: 'signup/',
      data
    })
  }

  requestResetPassword (data: { email: string }) {
    return this._instance.request({
      method: 'post',
      url: 'password_reset/',
      data
    })
  }

  resetPassword (data: { password: string; token: string }) {
    return this._instance.request({
      method: 'post',
      url: 'password_reset/confirm/',
      data
    })
  }

  validateToken (data: { token: string }) {
    return this._instance.request({
      method: 'post',
      url: 'password_reset/validate_token/',
      data
    })
  }
}

export const apiService = new ApiService()

export default apiService
