








































































































































import Vue from 'vue'

import ForecastLegend from './ForecastLegend.vue'
import ForecastOneHour from './ForecastOneHour.vue'
import TemperatureLine from '../../common/TemperatureLine/TemperatureLine.vue'

import DraggableComponent from '@/components/ui/DraggableComponent/DraggableComponent.vue'
import UpdateTime from '@/components/ui/UpdateTime/UpdateTime.vue'

import stylesVars from '@/styles/colors'

export default Vue.extend({
  name: 'forecast-hourly',
  components: {
    'draggable-component': DraggableComponent,
    'forecast-hourly-legend': ForecastLegend,
    'forecast-hourly-one-hour': ForecastOneHour,
    'temperature-line': TemperatureLine,
    'ui-update-time': UpdateTime
  },
  props: {
    displaySession: {
      type: Boolean,
      default: false
    },
    displayPreviousNight: {
      type: Boolean,
      default: true
    },
    displayHumidity: {
      type: Boolean,
      default: false
    },
    displayPressure: {
      type: Boolean,
      default: true
    },
    displayGust: {
      type: Boolean,
      default: true
    },
    displayTrackMinMax: {
      type: Boolean,
      default: false
    },
    displayChanceOfRain: {
      type: Boolean,
      default: false
    },
    displayHourlyInfo: {
      type: Boolean,
      default: true
    },
    data: {
      // type: Array
    },
    displayDay: {
      type: Boolean,
      default: true
    },
    flattenData: {
      // type: Array,
      default: () => ([])
    },
    flattenDataIndex: {
      type: Number,
      default: 0
    },
    fontBase: Number,
    loading: Boolean,
    isMobile: Boolean,
    isDesktop: Boolean,
    isLegendVisible: {
      type: Boolean,
      default: true
    },
    sliderItemWidth: Number,
    temperatureOverLimit: {
      type: Number,
      required: false
    },
    chanceOfRainOverLimit: {
      type: Array,
      required: false
    },
    windOverLimit: {
      type: Array,
      required: false
    },
    gustsOverLimit: {
      type: Array,
      required: false
    },
    humidityOverLimit: {
      type: Number,
      required: false
    },
    pressureOverLimit: {
      type: Number,
      required: false
    },
    trackMinOverLimit: {
      type: Number,
      required: false
    },
    trackMaxOverLimit: {
      type: Number,
      required: false
    }
  },
  data: () => ({
    mouseState: 'up',
    initialPositionX: null,
    initialPositionY: null,
    isPreviousNightCollapse: false,
    isWeatherConditionsCollapse: false,
    stylesVars,
    hideLegend: false
  }),
  mounted () {
    this.hideLegend = !!this.isMobile
    this.isPreviousNightCollapse = !!this.isMobile
    this.isWeatherConditionsCollapse = !!this.isMobile
  },
  computed: {
    temperaturesSet () {
      return this.flattenData.map(data => data.temperature)
    },
    temperatureBottom () {
      return 'calc(' +
        (this.isMobile ? stylesVars.OffsetBottomTemperatureLine__mobile : stylesVars.OffsetBottomTemperatureLine__default) +
        (this.displayHumidity ? '' : ' - ' + stylesVars.OneHourSize__humidity) +
        (this.displayPressure ? '' : ' - ' + stylesVars.OneHourSize__pressure) +
        (this.displayTrackMinMax ? '' : ' - ' + stylesVars.OneHourSize__track) +
        (this.displayGust ? '' : ' - ' + stylesVars.OneHourSize__wind + ' / 2') +
      ')'
    }
  },
  methods: {
    toggleLegend () {
      this.hideLegend = !this.hideLegend
    },
    togglePreviousNight () {
      this.isPreviousNightCollapse = !this.isPreviousNightCollapse
    },
    toggleWeatherConditions () {
      this.isWeatherConditionsCollapse = !this.isWeatherConditionsCollapse
    },
    displayTemperatureLine (indexDay, indexHour) {
      return indexDay === 0 &&
              indexHour === 0 &&
              this.flattenData.length > 0 &&
              this.temperaturesSet &&
              this.temperaturesSet.length > 0 &&
              this.sliderItemWidth
    }

  }
})

