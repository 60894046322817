import Vue from 'vue'
import { TimelineEvent, D3Timeline } from './D3Timeline'
import Loader from '@/components/ui/Loader/Loader.vue'

export default Vue.extend({
  name: 'ui-timeline',
  components: {
    'ui-loader': Loader
  },
  props: {
    loading: Boolean,
    availableTimeRangesLocal: {
      default: () => ([])
    },
    currentTime: String,
    currentTimeRadar: String,
    zoom: Number,
    maximumTimeLive: String,
    mode: String,
    dateRange: {
      type: Array,
      default: () => ([])
    },
    selectedRangeLocal: {
      default: () => ([])
    },
    live: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    sessions: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    isZoomMin () {
      return this.zoom === 1
    }
  },
  data () {
    return {
      isZoomMax: false
    }
  },
  mounted () {
    /**
     * @type D3Timeline
     */
    this._d3Timeline = new D3Timeline(
      this.$refs.D3Timeline,
      this.dateRange,
      this.mode,
      this.availableTimeRangesLocal,
      this.currentTime,
      // this.currentTimeRadar,
      this.maximumTimeLive,
      this.selectedRangeLocal,
      this.live,
      this.active,
      this.sessions,
      this.zoom
    )
    this._d3Timeline.on(TimelineEvent.onRangeChange, this.onD3TimelineRangeChange)
    this._d3Timeline.on(TimelineEvent.onTimeChange, this.onD3TimelineTimeChange)
    this._d3Timeline.on(TimelineEvent.onClickOutsideFuture, this.onD3TimelineLiveClick)
    this._d3Timeline.on(TimelineEvent.onClickOutsidePast, this.onD3TimelinePastClick)
    this._d3Timeline.on(TimelineEvent.onZoomChange, this.onD3TimelineZoomChange)
    this._d3Timeline.on(TimelineEvent.onClickPOI, this.onD3TimelineClickPOI)
  },
  beforeDestroy () {
    this._d3Timeline.off(TimelineEvent.onRangeChange, this.onD3TimelineRangeChange)
    this._d3Timeline.off(TimelineEvent.onTimeChange, this.onD3TimelineTimeChange)
    this._d3Timeline.off(TimelineEvent.onClickOutsideFuture, this.onD3TimelineLiveClick)
    this._d3Timeline.off(TimelineEvent.onClickOutsidePast, this.onD3TimelinePastClick)
    this._d3Timeline.off(TimelineEvent.onZoomChange, this.onD3TimelineZoomChange)
    this._d3Timeline.off(TimelineEvent.onClickPOI, this.onD3TimelineClickPOI)
    this._d3Timeline.destroy()
  },
  methods: {
    onD3TimelineRangeChange (newRange: [string, string], isLive: boolean) {
      this.$emit('change-range', newRange, isLive)
    },
    onD3TimelineTimeChange (newTime) {
      if (this.currentTime === newTime) return
      this.$emit('change-time', newTime)
    },
    onD3TimelineLiveClick () {
      this.$emit('go-live')
    },
    onD3TimelinePastClick () {
      this.$emit('go-past')
    },
    onD3TimelineZoomChange (zoom: number, isZoomMin: boolean, isZoomMax: boolean) {
      if (zoom === this.zoom) return
      this.$emit('change-zoom', zoom)
      this.isZoomMax = isZoomMax
    },
    onD3TimelineClickPOI (poi) {
      this.$emit('go-session', poi)
    },
    timelineZoomIn () {
      this._d3Timeline.zoomIn()
    },
    timelineZoomOut () {
      this._d3Timeline.zoomOut()
    },
    timelineCenter () {
      this._d3Timeline.center()
    },
    timelineReset () {
      this._d3Timeline.resetZoom()
    }
  },
  watch: {
    availableTimeRangesLocal (newValue) {
      this._d3Timeline.availableDataTimeRanges = newValue
    },
    currentTime (newValue: string) {
      this._d3Timeline.currentTime = newValue
    },
    currentTimeRadar (newValue: string) {
      // this._d3Timeline.currentTimeRadar = newValue
      this._d3Timeline.currentTime = newValue
    },
    dateRange (newValue) {
      this._d3Timeline.dateRange = newValue
    },
    selectedRangeLocal (newValue) {
      this._d3Timeline.selectedRange = newValue
    },
    live (newValue) {
      this._d3Timeline.isLive = newValue
    },
    active (newValue) {
      this._d3Timeline.isActive = newValue
    },
    maximumTimeLive (newValue) {
      this._d3Timeline.maximumTimeAvailable = newValue
    },
    sessions (newValue) {
      this._d3Timeline.sessions = newValue
    },
    zoom (newValue) {
      this._d3Timeline.zoom = newValue
    }
  }
})
