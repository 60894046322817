


























































import Vue from 'vue'
export default Vue.extend({
  name: 'forecast-one-hour',
  props: {
    active: Boolean,
    average: String,
    chanceOfRain: String,
    displayHumidity: Boolean,
    displayPressure: Boolean,
    displayChanceOfRain: Boolean,
    displayGust: Boolean,
    displayTrackMinMax: Boolean,
    displaySession: Boolean,
    gusts: String,
    hour: String,
    humidity: [Number, String],
    isAverageOverLimit: Boolean,
    pressure: [Number, String],
    prevealingWeather: Number,
    session: String,
    trackMax: [Number, String],
    trackMin: [Number, String],
    wind: String,
    chanceOfRainOverLimit: Array,
    windOverLimit: Array,
    gustsOverLimit: Array,
    humidityOverLimit: Number,
    pressureOverLimit: Number,
    trackMinOverLimit: Number,
    trackMaxOverLimit: Number
  },
  computed: {
    isChanceOfRainOverLimit () {
      return this.chanceOfRain && this.chanceOfRainOverLimit ? this.chanceOfRainOverLimit.indexOf(this.chanceOfRain) >= 0 : false
    },
    isWindOverLimit () {
      return this.average && this.windOverLimit ? this.windOverLimit.indexOf(this.average) >= 0 : false
    },
    isGustsOverLimit () {
      return this.gusts && this.gustsOverLimit ? this.gustsOverLimit.indexOf(this.gusts) >= 0 : false
    },
    isHumidityOverLimit () {
      return this.humidity && this.humidityOverLimit ? this.humidity >= this.humidityOverLimit : false
    },
    isPressureOverLimit () {
      return this.pressure && this.pressureOverLimit ? this.pressure >= this.pressureOverLimit : false
    },
    isTrackMinOverLimit () {
      return this.trackMin && this.trackMinOverLimit ? this.trackMin >= this.trackMinOverLimit : false
    },
    isTrackMaxOverLimit () {
      return this.trackMax && this.trackMaxOverLimit ? this.trackMax >= this.trackMaxOverLimit : false
    }
  }
})
