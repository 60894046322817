











































































import Vue from 'vue'

export default Vue.extend({
  name: 'WeatherStationDetail',
  props: {
    airTemperature: {},
    trackTemperature: {},
    windDirection: String,
    windSpeed: {},
    gusts: {},
    rainIntensityText: String,
    rainIntensity: [Number, String],
    humidity: {},
    pressure: {},
    timeToDisplay: String,
    timeFormatted: String,
    isAirTemperatureOverLimit: Boolean,
    isTrackTemperatureOverLimit: Boolean,
    isWindSpeedOverLimit: Boolean,
    isGustsOverLimit: Boolean,
    isRainIntensityOverLimit: Boolean,
    isHumidityOverLimit: Boolean,
    isPressureOverLimit: Boolean,
    displayPressure: {
      type: Boolean,
      default: true
    },
    displayHumidity: {
      type: Boolean,
      default: true
    },
    variant: {
      type: String,
      default: 'normal',
      validator (v) {
        return ['normal', 'tiny'].indexOf(v) > -1
      }
    },
    reference: {
      type: String,
      default: ''
    },
    displayTime: {
      type: Boolean,
      default: true
    }
  }
})

